export const environment = {
    // production: false,

    // LocalUrl: 'http://localhost:3000/',          //........... Local........

    // LiveUrl:"https://agentapi.stage.insurancesamadhan.com/",  //........ Stage........

 ActiveUrl: 'https://agentapi.insurancesamadhan.com/',

 awsUrl:'https://insurance-samadhan-docs.s3.ap-south-1.amazonaws.com/'
};
