import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'data-service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import swal from 'sweetalert';
import { AuthService } from 'app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [DataService]
})

export class SignUpComponent implements OnInit,OnDestroy {

  user: any;
  signUpForm: FormGroup;
  submitted = false;
  isAuthenticated;
  authStatusSubs: Subscription;

  constructor(private dataService: DataService,
              private fb: FormBuilder,
              private router: Router,
              private ngFlashMessageService: NgFlashMessageService,
              private authService: AuthService) { }

  ngOnInit() {
    this.signUpForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      // user_id: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      // password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.authService.autoAuthUser();
        this.isAuthenticated = this.authService.getIsAuth();
        this.authStatusSubs = this.authService.getAuthStatusListener()
        .subscribe(auth => {
            this.isAuthenticated = auth;
        });

    if (this.isAuthenticated) {
      this.router.navigate(['/agent/dashboard']);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signUpForm.controls;
  }

  SignUp() {
    this.submitted = true;

    if (this.signUpForm.invalid) {
      return;
    }
    const newUser = this.signUpForm.value;
    //this.authService.signUp(newUser);

  }

  ngOnDestroy() {
    this.authStatusSubs.unsubscribe();
  }
}
