import { Component, OnInit } from '@angular/core';
import { DataService } from 'data-service/data.service';
import { Router } from '@angular/router';
import swal from 'sweetalert';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  providers: [DataService]
})
export class AgreementComponent implements OnInit {
  agreement: any;
  user: any;
  Token: string;
  user_id: string;

  constructor(private dataService: DataService, private router: Router) {
    this.Token = localStorage.getItem('Token');
    this.user_id = localStorage.getItem('user_id');
  }

  ngOnInit() {
    this.dataService.getUser(this.user_id)
      .subscribe(user => {
      this.user = user.data;
        console.log(user)
      })
  }

  AgreeYes() {
    let agreementData = {
      contract_status: this.agreement,
      user_id: localStorage.getItem('user_id')
    }
    // console.log(agreementData)
    if (agreementData.contract_status != true) {
      swal("Error", "Please tick the checkbox to agree to the Terms and Conditions.", "error")
      return true;
    } else {
      this.dataService.acceptAgreement(agreementData)
        .subscribe(user => {
        this.user = user.data;
          //console.log(user.data.contract_status)
          if (agreementData.contract_status != null) {
            swal("Congrats! Welcome to INSA Family", "Agreement Accepted!!", "success")
            this.router.navigate(['/agent/dashboard']);
          } else {
            return true;
          }
        });
    }
  }
  AgreeNo() {
    swal('why Select NO ?',
    `You would not be able to Login if you select NO. If any issue regarding Terms and Conditions,
    Please call Deepak Uniyal (+91-9811667970) or drop a mail on deepak@insurancesamadhan.com`,
    'error')
    // this.router.navigate(['/agent/agreement']);
    return true;
  }
}
