import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
// import {AuthService} from '../services/auth.service';
import {NotifyService} from '../../services/notify.service';

import { S_Login } from '../../models/login';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DataService } from 'data-service/data.service';
import swal from 'sweetalert';
import { AuthService } from 'app/services/auth.service';
import { Subscription } from 'rxjs';
//import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [DataService]
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  submitted = false;
  user: any;
  marked = false;
  theCheckbox = false;
  password: String = 'password';
  isAuthenticated;
  authStatusSubs: Subscription;

  constructor(private fb: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private _notifyService:NotifyService,
    private authService: AuthService) {

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.authService.autoAuthUser();
        this.isAuthenticated = this.authService.getIsAuth();
        this.authStatusSubs = this.authService.getAuthStatusListener()
        .subscribe(auth => {
            this.isAuthenticated = auth;
        });

    if (this.isAuthenticated) {
      this.router.navigate(['/agent/dashboard']);
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  Login() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    } else {
      let userlogin = this.loginForm.value;
      this.authService.loginUser(userlogin);
    
    }
  }
  toggleVisibility(e) {
    this.marked = e.target.checked;
    //console.log(this.marked)
    var mark = this.marked;
    if (mark === true) {
      this.password = "text";
      // console.log("if",this.password)
    } else {
      this.password = "password";
      // console.log("else",this.password)
    }
  }

  ngOnDestroy() {
    this.authStatusSubs.unsubscribe();
  }
}
