import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    isAdminView: boolean;
}
export const ROUTES: RouteInfo[] = [
    { path: '/agent/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', isAdminView: false },
    { path: '/agent/user/profile', title: 'Profile', icon: 'person', class: '', isAdminView: false },
    { path: '/agent/case/cases', title: 'Cases', icon: 'group_work', class: '', isAdminView: false },
    { path: '/agent/policy', title: 'Policy', icon: 'assignment', class: '', isAdminView: true },
    { path: '/agent/user/changePassword', title: 'Change Password', icon: 'person', class: '', isAdminView: false },
    { path: '/agreement', title: 'Agreement', icon: 'person', class: '', isAdminView: false },
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
    menuItems: any[];
    authService: AuthService;
    group: string;
    isAuthenticated;
    authStatusSubs: Subscription;

    constructor(private _authService: AuthService,
                private router: Router) {}

    ngOnInit() {
        this.authService = this._authService;
        this.group = localStorage.getItem('group');
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        this.authService.autoAuthUser();
        this.isAuthenticated = this.authService.getIsAuth();
        this.authStatusSubs = this.authService.getAuthStatusListener()
        .subscribe(auth => {
            this.isAuthenticated = auth;
        });
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    logout() {
        const hi = confirm('Are you sure you want to Logout?');
        if (hi == true) {
            this.authService.logout();
        }
    }

    ngOnDestroy() {
        this.authStatusSubs.unsubscribe();
    }
}
