import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {NotifyService} from '../services/notify.service';

import { HttpService } from './http.service';
import { apiEndpoint } from '../models/endpoints';
import { environment } from './../../env/env';

// Models
import { S_Auth } from '../models/auth';
import { S_Login } from '../models/login';
import { HttpClient } from '@angular/common/http';
import { group } from '@angular/animations';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    private _authKey = 'authorization_token';
    private baseUrl = `${environment.ActiveUrl}`;
    private token;
    private user_id;
    private isAuthenticated;
    private authStatusList = new Subject<boolean>();
    private userGroup;
    private email;
    private name;
    private id;
    private tokenTimer: any;
    private userData;


    constructor(private router: Router,
        private _httpService: HttpService,
        private http: HttpClient,
        private _notifyService:NotifyService) { }


    getAuth = (): S_Auth => {
        const tokenObj = sessionStorage.getItem(this._authKey);
        if (!tokenObj) {
            return new S_Auth({ userType: false, token: false });
        }
        return JSON.parse(tokenObj) as S_Auth;
    }

    setAuth = (obj: any) => sessionStorage.setItem(this._authKey, JSON.stringify(obj));

    isLoggedIn = (): boolean => {
        const tokenObj: S_Auth = this.getAuth();
        return !!tokenObj.token;
    };

    isAdmin = (): boolean => {
        const tokenObj: S_Auth = this.getAuth();
        return tokenObj.userType === 'admin';
    }

    getUserType = (): string => {
        const tokenObj: S_Auth = this.getAuth();
        return tokenObj.userType;
    }

    getToken = (): string => {
        const tokenObj: S_Auth = this.getAuth();
        return tokenObj.token;
    }

    getAuthStatusListener() {
        return this.authStatusList.asObservable();
    }

    getIsAuth() {
        return this.isAuthenticated;
    }

    getUserId() {
        return this.user_id;
    }

    getEmail() {
        return this.email;
    }

    getName() {
        return this.name;
    }

    private setAuthTimer(duration) {
        this.tokenTimer = setTimeout(() => {
            this.logout();
        }, duration * 1000);
    }

    private getAuthData() {
        const token = localStorage.getItem('Token');
        const name = localStorage.getItem('name');
        const userId = localStorage.getItem('userId');
        const id = localStorage.getItem('id');
        const email = localStorage.getItem('email');
        const userGroup = localStorage.getItem('group');
        const expiry = localStorage.getItem('expiry');

        const userData = {
            token,
            name,
            user_id: userId,
            id,
            email,
            userGroup,
            expiry: new Date(expiry),
        };
        this.userData = userData;
        if (!token) {
            return;
        }
        return userData;
    }

    autoAuthUser() {
        this.getAuthData();
        const authInfo = this.userData;
        if (!authInfo) {
            return;
        }

        const expiresIn = authInfo.expiry.getTime() - Date.now();
        if (expiresIn > 60000) {
            this.token = authInfo.token;
            this.isAuthenticated = true;
            this.authStatusList.next(true);
            this.name = authInfo.name;
            this.user_id = authInfo.user_id;
            this.id = authInfo.id;
            this.email = authInfo.email;
            this.userGroup = authInfo.group;
            this.setAuthTimer(expiresIn / 1000);
        }
    }

    signUp(newUser) {
        this.http.post<any>(`${this.baseUrl}signup`, newUser)
            .subscribe(res => {
                if (res.status == 201) {
                    this._notifyService.success({message: res.msg});
                    this.router.navigate(['/auth/login']);
                }else{
                    this._notifyService.danger({message: res.msg});
                }
            }, (error) => {
                console.log(error.error);
            });
    }

    loginUser(userData) {
        this.http.post<any>(`${this.baseUrl}login`, userData)
            .subscribe(res => {
                if (res.token) {
                   // console.log(res.data);
                    // console.log(res.token);
                    if (res.data.contract_status == 'true') {
                        this._notifyService.success({message: res.msg});
                        this.token = res.token;
                        const data = res.data;
                        const expiresIn = 259200;
                        this.setAuthTimer(expiresIn);
                        const expiryDuration = new Date(Date.now() + expiresIn * 1000);

                        this.isAuthenticated = true;
                        this.authStatusList.next(true);

                        this.user_id = data.user_id;
                        this.userGroup = data.group;
                        this.email = data.email;
                        this.name = data.name;
                        this.id = data.id;

                        localStorage.setItem('Token', this.token);
                        localStorage.setItem('group', data.group);
                        localStorage.setItem('user_id', data.user_id);
                        localStorage.setItem('name', data.name);
                        localStorage.setItem('email', data.email);
                        localStorage.setItem('id', data._id);
                        localStorage.setItem('expiry', expiryDuration.toISOString());

                        this.router.navigate(['/agent/dashboard']);
                    } else {
                        localStorage.setItem('user_id', res.data.user_id);
                        this.router.navigate(['/agreement']);
                    }
                }else{
                    this._notifyService.danger({message:res.msg });
                }
            }, error => {
                console.log(error.error);
            });
    }


    logout() {
        this.token = null;
        this.isAuthenticated = false;
        this.user_id = null;
        this.name = null;
        this.email = null;
        this.id = null;
        this.authStatusList.next(false);
        sessionStorage.removeItem(this._authKey);
        localStorage.clear();
        clearTimeout(this.tokenTimer);
        this.router.navigate(['/auth/login']);
    }
    // login = (loginObj:S_Login)=> this._httpService.post(apiEndpoint.LOGIN, loginObj, this.getAuth())
}
