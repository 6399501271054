import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class NotifyService {

    placement = {
        TOP_LEFT: {
            from: 'top',
            align: 'left'
        },
        TOP_CENTER: {
            from: 'top',
            align: 'center'
        },
        TOP_RIGHT: {
            from: 'top',
            align: 'right'
        },
        BOTTOM_LEFT: {
            from: 'bottom',
            align: 'left'
        },
        BOTTOM_CENTER: {
            from: 'bottom',
            align: 'center'
        },
        BOTTOM_RIGHT: {
            from: 'bottom',
            align: 'right'
        }
    };

    constructor() {}

    /**
     * Show notification
     */
    private showNotification(opt: any) {
        const type = ['', 'info', 'success', 'warning', 'danger'];

        if (!opt.placement) opt.placement = { from: 'top', align: 'right' };
        if (!opt.type || (type.indexOf(opt.type) == -1)) opt.type = 'info';
        if (!opt.icon) opt.icon = 'notifications';
        $.notify({
            icon: opt.icon,
            message: opt.message

        }, {
            type: opt.type,
            timer: 3000,
            placement: opt.placement,
            template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
                '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
                '<i class="material-icons" data-notify="icon">' + opt.icon + '</i> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                '</div>' +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>'
        });
    }


    primary(opt: any) {
        if (!opt.message) opt.message = '';
        if (!opt.placement) opt.placement = this.placement.TOP_RIGHT;
        if (!opt.type) opt.type = 'primary';
        if (!opt.icon) opt.icon = 'notifications';
        this.showNotification(opt);
    }

    info(opt: any) {
        if (!opt.message) opt.message = '';
        if (!opt.placement) opt.placement = this.placement.TOP_RIGHT;
        if (!opt.type) opt.type = 'info';
        if (!opt.type) opt.type = 'info';
        this.showNotification(opt);
    }

    success(opt: any) {
        if (!opt.message) opt.message = '';
        if (!opt.placement) opt.placement = this.placement.TOP_RIGHT;
        if (!opt.type) opt.type = 'success';
        if (!opt.icon) opt.icon = 'done';
        this.showNotification(opt);
    }

    warning(opt: any) {
        if (!opt.message) opt.message = '';
        if (!opt.placement) opt.placement = this.placement.TOP_RIGHT;
        if (!opt.type) opt.type = 'warning';
        if (!opt.icon) opt.icon = 'warning';
        this.showNotification(opt);
    }

    danger(opt: any) {
        if (!opt.message) opt.message = '';
        if (!opt.placement) opt.placement = this.placement.TOP_RIGHT;
        if (!opt.type) opt.type = 'danger';
        if (!opt.icon) opt.icon = 'error';
        this.showNotification(opt);
    }
}
