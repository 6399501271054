import { Component, OnInit } from '@angular/core';
import { DataService } from 'data-service/data.service';
import { Router } from '@angular/router';
import {NotifyService} from '../services/notify.service';
import {FormBuilder,FormGroup,Validators} from "@angular/forms";
import swal from 'sweetalert';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPass:FormGroup;
  submitted = false;
  user: any;
  constructor (private fb:FormBuilder, private dataService:DataService, 
    private _notifyService:NotifyService,private router:Router){ }

  ngOnInit() { this.forgotPass = this.fb.group({
    email: ['', [Validators.required,Validators.email]],
    // old_password: ['', [Validators.required]],
    // new_password:['', [Validators.required, Validators.minLength(8)]]
   });
}
 // convenience getter for easy access to form fields
 get f() { return this.forgotPass.controls; }

 ForgotPass(){
  this.submitted = true;
  // stop here if form is invalid
  if (this.forgotPass.invalid) {
    return;
  } else {
    let changeValue = this.forgotPass.value;
      console.log(changeValue)
     this.dataService.forgotPassw(changeValue)
     .subscribe(user =>{this.user = user.data;
      // console.log(user)
      if(user.data){
        swal("Success", "Please Check Your Mail for new Password!", "success")
        this.router.navigate(['/auth/login'])
      }else{
        this._notifyService.danger({message:user.msg})
       return true;
      }
   })
 }
 }
}
