import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
// import {AuthService} from './services/auth.service';
// import {StateService} from './services/state.service';
// import {NotifyService} from './services/notify.service';
// import {GlobalErrorHandlerService} from './services/global-error-handler.service';
// ...........................Material..............................
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { AgmCoreModule } from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
// import { LoginComponent } from './auth/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
// import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { DataService } from 'data-service/data.service';
// Import the library
import { NgFlashMessagesModule } from 'ng-flash-messages';
// import { UsersComponent } from './user/users/users.component';
import { CasesComponent } from './case/cases/cases.component';
// import { ProfileComponent } from './user/profile/profile.component';
import { PolicyComponent } from './policy/policy.component';
import { AddCaseComponent } from './case/add-case/add-case.component';
import { AgreementComponent } from './agreement/agreement.component';
// import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AllCasesComponent } from './case/all-cases/all-cases.component';
// import { UserDetailsComponent } from './user/user-details/user-details.component';
import { CaseDetailsComponent } from './case/case-details/case-details.component';
import { InsCompanyComponent } from './ins-company/ins-company.component';
import { CaseMoreInfoComponent } from './case/case-more-info/case-more-info.component';
// import { AddUserComponent } from './user/add-user/add-user.component';
import { ReportComponent } from './report/report.component';
import { ViewHistoryComponent } from './view-history/view-history.component';
import { StatusComponent } from './status/status.component';
import { AdvisorComponent } from './advisor/advisor.component';
//import { ViewagreementComponent } from './viewagreement/viewagreement.component';
import { ClientcontractComponent } from './clientcontract/clientcontract.component';
import { AuthModule } from './auth/auth.module';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        Ng2SearchPipeModule,
        NgxPaginationModule,
        NgxUiLoaderModule,
        MatInputModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        AuthModule,
        // Specify the library as an import
        NgFlashMessagesModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        })
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        PolicyComponent,
        AgreementComponent,
        ForgotPasswordComponent,
        ClientcontractComponent
        // LoginComponent,
        // SignUpComponent,
        // UsersComponent,
        // CasesComponent,
        // ProfileComponent,
        // AddCaseComponent,
        // ChangePasswordComponent,
        // AllCasesComponent,
        // UserDetailsComponent,
        // CaseDetailsComponent,
        // InsCompanyComponent,
        // CaseMoreInfoComponent,
        // AddUserComponent,
        // ReportComponent,
        // ViewHistoryComponent,
        // StatusComponent,
        // AdvisorComponent,
        // ViewagreementComponent,
        // ClientcontractComponent,

    ],
    providers: [DataService],
    // providers: [AuthService, NotifyService, StateService, {provide: ErrorHandler, useClass: GlobalErrorHandlerService}],
    bootstrap: [AppComponent]
})
export class AppModule {
}
