import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';
import { AuthGuard } from './auth/auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AgreementComponent } from './agreement/agreement.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ClientcontractComponent } from './clientcontract/clientcontract.component';
//import { ViewagreementComponent } from './viewagreement/viewagreement.component';


const routes: Routes = [
    { path: '', redirectTo: 'auth/signup', pathMatch: 'full' },
    { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
    {
        path: 'agent',
        component: AdminLayoutComponent,
        loadChildren: './layouts/admin-layout.module#AdminLayoutModule'
    },
    // { path: '**', redirectTo: '/auth/login' },
    {
        path:'agreement',
        component:AgreementComponent
    },
    // {
    //     path:'oldagreement',
    //     component:ViewagreementComponent
    // },
    { 
        path: 'contract/:id',
        component: ClientcontractComponent,
        pathMatch: 'full' },
    {
        path:'forgotPassword',
        component:ForgotPasswordComponent
    }

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [],
})
export class AppRoutingModule {
}
