import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'data-service/data.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-clientcontract',
  templateUrl: './clientcontract.component.html',
  styleUrls: ['./clientcontract.component.scss']
})
export class ClientcontractComponent implements OnInit {
  caseone: any = {};
  contract: any;

  constructor(private route:ActivatedRoute, private dataService:DataService,private router:Router) { }

  ngOnInit() {
    this.getCase(this.route.snapshot.params['id']);
  }
  getCase(id){
    this.dataService.getOneCase(id)
       .subscribe(caseone =>{this.caseone = caseone.data;
       console.log(caseone)
       if(! caseone.data){
         swal("Error","You seems to have landed on a wrong link.", "error",{timer: 10000})
        // window.location.href = 'https://insurancesamadhan.com/';
       }
      })
  }

  AgreeYes(id){
    var clientAgrrments ={
       id:id,
       client_agreement_status:this.contract
    }
  //  console.log(clientAgrrments)
    if(clientAgrrments.client_agreement_status != true){
      swal("Error","Please tick the checkbox to agree to the Terms and Conditions.", "error")
      return true;
    }
    this.dataService.ClientAgreement(clientAgrrments)
      .subscribe(caseone =>{this.caseone = caseone.data;
       // console.log(caseone)
       if(caseone){
        swal("Congrats!", "You have accepted the contract. We will now start working on your case. We will keep you updated about the progress of the case.Thank You! Team Insurance Samadhan.", "success")
        // window.location.href = 'https://insurancesamadhan.com/';
        this.getCase(id);
       }else{
        return true;
       }

      })
  }
  AgreeNo(){
    swal("why Select No ?", "We would not be able to Process your case if you select No. If any issue regarding Terms and Conditions, Please call Deepak Uniyal (+91-9811667970) or drop a mail on deepak@insurancesamadhan.com ", "error")
   // this.router.navigate(['/agent/agreement']);
   return true;
  }
}
