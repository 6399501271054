import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {S_Auth} from '../models/auth';

@Injectable({
    providedIn: 'root'
})

export class HttpService {

    constructor(private _httpClient:HttpClient) {
    }

    get = (url, auth:S_Auth)=> {
        let headers = {'content-type': 'application/json'};
        if (auth.token) headers['Authorization'] = auth.token;
        return this._httpClient.get(url,{headers:headers});
    }

    put = (url, body:Object = null, auth:S_Auth)=> {
        let headers = {'content-type': 'application/json'};
        if (auth.token) headers['Authorization'] = auth.token;
        return this._httpClient.put(url, body, {headers: headers});
    }

    post = (url, body:Object = null, auth:S_Auth)=> {
        let headers = {'content-type': 'application/json'};
        if (auth.token) headers['Authorization'] = auth.token;
        return this._httpClient.post(url, body, {headers: headers});
    }

    delete = (url, auth:S_Auth)=> {
        let headers = {'content-type': 'application/json'};
        if (auth.token) headers['Authorization'] = auth.token;
        return this._httpClient.delete(url, {headers: headers});
    }

    getFile = (url, auth:S_Auth)=> {
        let headers = {'content-type': 'application/json'};
        if (auth.token) headers['Authorization'] = auth.token;
        return this._httpClient.get(url,{headers:headers, responseType: 'blob'});
    }

}


