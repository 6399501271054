import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../env/env';

@Injectable()
export class DataService {
  Url: String = environment.ActiveUrl; //  ...Server

  constructor(private http: Http) {}

  setHeaders(headers: Headers) {
    headers.append('content-Type', 'application/json');
    headers.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE');
    headers.append('Authorization', `Bearer ${localStorage.getItem('Token')}`);
  }
  //  ...........No of Users................................
  noOfUsers() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'noOfUsr', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of Users................................
  activeUser() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'activeUser', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of Users................................
  inActiveUser() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'inActiveUser', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of Case................................
  noOfCases() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'noOfCase', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .................Advisore Dashboard Section................................
  AdisoreCases(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'advisorCases/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  AdisorePendingCase(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'advisorPCases/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  AdvisoreAcceptCase(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'advisorACases/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  AdvisoreRejectCase(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'advisorRCases/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of Reject Case................................
  rejectCase() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'rejectedCase', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of Accept Case................................
  acceptCase() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'acceptedCase', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of In Progress Case................................
  inProgress() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'inProgressCase', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...........No of Pending Case................................
  pendingCase() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'pendingCase', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .............Add User....................................

  //  .............Add User....................................
  addUser(newUser) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'addUser', newUser, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ##################################### Cases Report ######################################
  //  ......................Search Date Wise month wise Data Case............
  FindDateWise(date) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'dateWise', date, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .......................Search Data caseSataus wise...................
  seartchByStatus(caseStatus) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'statusWise', caseStatus, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .......................Search Data caseSataus wise...................
  seartchByAgent(user) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'agentWise', user, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  // ############################################# Users Report.#############################
  UsersDateWise(user) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'dateWise', user, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  UsersStatusWise(status) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'statusWise', status, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  UsersGroupWise(group) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'groupWise', group, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ....................Update User Satatus...........................
  updateUserStatus(user) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'updatedStatus', user, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .................LOGIN Users.......................................

  //  ...................Change Password............................
  changePass(changeValue) {
    const headers = new Headers();
    headers.append('content-Type', 'application/json');
    return this.http
      .post(this.Url + 'changePass', changeValue, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..........................LOGOUT ..............................
  logOut() {
    return this.http.get(this.Url + 'logout').pipe(map((res) => res.json()));
  }

  //  ................FORGOT PASSWORD......................
  forgotPassw(formValue) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'forgotPassw', formValue, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .........GET Users....................................
  getUsers(page, pageSize) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'users' + `?page=${page}`, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  getAdvisors(page, pageSize) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'advisors' + `?page=${page}`, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  getRef(){
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'ref-user' , { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .............Edit User.............................
  getUser(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    headers.append('content-Type', 'application/json');
    return this.http
      .get(this.Url + 'user/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }

  //  .................UPDATE USER........................
  updateUser(id, editedUser) {
    const headers = new Headers();
    headers.append('content-Type', 'application/json');
    return this.http
      .put(this.Url + 'edit/' + id, editedUser, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ................User Group Of all User..............
  getAssignUsers() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'assignUserAgent', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  getAssignExpert(){
    const headers = new Headers();
      this.setHeaders(headers);
    return this.http.get(this.Url + 'assignExpert', { headers: headers })
      .pipe(map(res => res.json()));
  }
  AssignToExpert(caseone){
    let headers = new Headers();
      this.setHeaders(headers);
    return this.http.post(this.Url +'case/assignToExpert',caseone ,{ headers: headers })
      .pipe(map(res => res.json()));
    }
  //  .............CHANGE STATUS..........................
  acceptAgreement(agreementData) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'userAgree', agreementData, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..........Deconste User.............................
  deleteUser(id) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('Token')}`);
    return this.http
      .delete(this.Url + 'user/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ........Get Policy by Agent......................
  getProfile(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'profile/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ................Update User Profile................
  completePro(userProfile) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'comp_profile', userProfile, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ........Get Policy by Agent......................
  getPolicy(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'pilicy/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..........Get Single Case costumer.......................
  getOneCase(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'caseone/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..................Accept Client Agreements......................
  ClientAgreement(clientAgrrments) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'clientAgree', clientAgrrments, {
        headers: headers,
      })
      .pipe(map((res) => res.json()));
  }
  //  ..........Get Single Case.......................
  getSingleCase(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'SingleCase/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ........Get Case by Agent......................
  getCase(casedata, page) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case' + `?page=${page}`, casedata, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //.......................Search Case.............................
  SearchByPolicy(id, agentId?){
    let headers = new Headers();
    this.setHeaders(headers);
    if(agentId){
      return this.http.get(this.Url +'case/byId/'+id + `?agentId=${agentId}`, { headers: headers })
    .pipe(map(res => res.json()));
    }
    else{
      return this.http.get(this.Url +'case/byId/'+id, { headers: headers })
    .pipe(map(res => res.json()));
    }
  }
  //  ..................Accept Case............................
  AcceptCase(changeStatus) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'caseStatus', changeStatus, {
        headers: headers,
      })
      .pipe(map((res) => res.json()));
  }
  //  ..................Assign To User......................
  AssignToUser(Assigned) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'assignToUser', Assigned, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..................Get Cases By Assigned User Wise........
  getCaseAssigned(page, casedata) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'assignedCasesAgent' + `?page=${page}`, casedata, {
        headers: headers,
      })
      .pipe(map((res) => res.json()));
  }
  //  .................GEt All case.......................
  getAllCase(page) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/all' + `?page=${page}`,'' ,{ headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..................Add Company Section................
  addComapny(newCompany) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'insComp/' + 'addCompany', newCompany, {
        headers: headers,
      })
      .pipe(map((res) => res.json()));
  }
  //  ....................Get Comapny...................
  getComapnys() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'insComp/', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .........................Get Single...............
  getComapnySingle(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'insComp/' + 'single/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  UpdateCompany(comapnyData) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'insComp/' + 'edit', comapnyData, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ........................Company name by policy type........
  getComapny(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'insComp/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ................Add Case ......................
  addCase(newCase) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'addCase', newCase, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...................Docs Upload............................
  docPost() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'docPost', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .....................Add more Details About Case..............
  addMore(CaseData, id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'addMore/' + id, CaseData, {
        headers: headers,
      })
      .pipe(map((res) => res.json()));
  }
  //  ..................GEt Case COmments. Single....................
  getCaseComments(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'case/' + 'comments/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ...................Post COmments for Cases..............
  postComment(comments) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'postComment', comments, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .............Add Status.................................
  addStatus(newStatus) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'status/' + 'addStatus', newStatus, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..................Get Status...............................
  getStatus() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'status/', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  .....................Get Active Status.....................
  getActiveStatus() {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'status/activeStatus', { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..................Get Status...............................
  getSingleStatus(id) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'status/' + id, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ..................Update Status...............................
  UpdateStatus(Status) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'status/' + 'edit', Status, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  //  ################################ Advisor Section......................................
  seartchAgent(agent) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'search', agent, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  SendSms(sms) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'sendSms', sms, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  removeDoc(doc) {
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .post(this.Url + 'case/' + 'removeDoc', doc, { headers: headers })
      .pipe(map((res) => res.json()));
  }
  allAdvisores(){
    const headers = new Headers();
    this.setHeaders(headers);
    return this.http
      .get(this.Url + 'allAdvisores', { headers: headers })
      .pipe(map((res) => res.json()));
  }
}
