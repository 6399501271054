import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFlashMessagesModule } from 'ng-flash-messages';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // NgFlashMessagesModule,
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
  ]
})
export class SharedModule { }
